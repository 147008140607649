import { Form } from './form-types';
import { MatterClient } from './matter-client-types';
import { DiscriminatedMatterDetails } from './matter-details.types';
import { User } from './user-types';

interface Engagement {
  id: string;
  status: string;
  letter_path: string;
  matter: string;
  client: string;
}

interface Conversation {
  id: string;
  format: string;
  name: string;
  transcript_path: string;
  audio_path: string;
  firm_user: string;
  start_timestamp: string;
  end_timestamp: string;
  matter: string;
  client: string;
}

type MatterKeyConsiderationSeverity = 'Green' | 'Yellow' | 'Red' | 'Gray';

export const MatterKeyConsiderationSeverities: MatterKeyConsiderationSeverity[] =
  ['Green', 'Yellow', 'Red', 'Gray'];

export enum MatterKeyConsiderationCategory {
  // Mandatory Flags
  CLEAR_LIABILITY = 'Clear Liability',
  SIGNIFICANT_INJURIES = 'Significant Injuries',
  INSURANCE_COVERAGE = 'Insurance Coverage',

  // Yellow Flags
  POTENTIALLY_TOUGH_CLIENT = 'Potentially Tough Client',
  CLIENT_IS_A_MINOR = 'Client is a Minor',
  HAD_PREVIOUS_REPRESENTATION = 'Had Previous Representation',
  PRE_EXISTING_INJURIES = 'Pre-existing Injuries',
  MINIMAL_PROPERTY_DAMAGE = 'Minimal Property Damage',
  PRIOR_LEGAL_ISSUES = 'Prior Legal Issues',
  SUBSTANCE_USE = 'Substance Use',
  INCONSISTENT_STATEMENTS = 'Inconsistent Statements',
  INSUFFICIENT_EVIDENCE = 'Insufficient Evidence',

  // Red Flags
  CLIENT_CRIMINAL_ACTIVITY = 'Client Criminal Activity',
  EVIDENCE_CONTRADICTS_CLIENT = 'Evidence Contradicts Client',
  STATUTE_OF_LIMITATIONS = 'Statute of Limitations',
  MEDICAL_TREATMENT_DELAYED = 'Medical Treatment Delayed',
  PAST_MEDICAL_HISTORY = 'Past Medical History',
}

export const MandatoryFlags: MatterKeyConsiderationCategory[] = [
  MatterKeyConsiderationCategory.CLEAR_LIABILITY,
  MatterKeyConsiderationCategory.SIGNIFICANT_INJURIES,
  MatterKeyConsiderationCategory.INSURANCE_COVERAGE,
];

interface MatterKeyConsideration {
  id: string;
  category: MatterKeyConsiderationCategory;
  severity: MatterKeyConsiderationSeverity;
  content: string;
}

interface MatterSummary {
  id: string;
  category: 'Accident' | 'Damages';
  content: string;
}

interface ActionItem {
  id: string;
  content: string;
  matter: string;
}

interface BaseMatter {
  id: string;
  name: string;
  type: string;
  status?: string;
}

interface BaseDetailedMatter extends BaseMatter {
  creator?: User;
  firm: string;
  engagements: Engagement[];
  clients: MatterClient[];
  conversations: Conversation[];
  key_considerations: MatterKeyConsideration[];
  summaries: MatterSummary[];
  action_items: ActionItem[];
  form?: Form;
}

type DetailedMatter = BaseDetailedMatter & DiscriminatedMatterDetails;

type MatterUpdate = Pick<BaseMatter, 'name'>;

export enum MatterStatus {
  INTAKE = 'Intake',
  CASE_SETUP = 'Case Setup',
  TREATMENT = 'Treatment',
  DEMAND_PREPARATION = 'Demand Preparation',
  DEMAND_COMPLETE = 'Demand Complete',
}

export interface CreateMatterRequest {
  firm: string;
  name: string;
  status?: MatterStatus;
  type?: string;
  creator: string;
  clients: { phone_numbers: { phone_number: string }[] }[];
}

export type {
  BaseMatter,
  Conversation,
  DetailedMatter,
  Engagement,
  MatterKeyConsideration,
  MatterKeyConsiderationSeverity,
  MatterSummary,
  MatterUpdate,
};
